/**
 * Created by Mike on 10/20/2015.
 */
angular.module('uti.skayo.onlinesale').controller('RecoverPasswordConfirmController',function($scope, $http, $routeParams, gettextCatalog, SweetAlert,$location ){

    //show password ui only after the code has been checked
    $scope.showPasswordUI = false;
    $scope.message = "";

    $scope.password = "";
    $scope.passwordConfirm = "";
    $scope.code = $routeParams.code;

    $http.get('api/passwordRecoverConfirm/'+$routeParams.code)
        .then(function(){
            $scope.showPasswordUI = true;
        });

    $scope.changePassword = function(){
        $http.post('api/passwordRecoverConfirm', { code : $scope.code, password: $scope.password, passwordConfirm: $scope.passwordConfirm})
            .then(function(){
                SweetAlert.swal({
                    title: '',
                    text: gettextCatalog.getString('The password has been successfully changed')
                },function(){
                    //redirect to login
                    $location.path('/login');
                });
            });
    };

    $scope.validation = {
        getRequiredText : function(){ return gettextCatalog.getString("Required field"); },
        getInvalidEmailText : function() { return gettextCatalog.getString("Invalid email"); },
        getSameText: function() { return gettextCatalog.getString("This field needs to be the same as the previous one"); },
        getTooShortText : function(){ return gettextCatalog.getString("Field too short");},
        getTooLongText: function() { return gettextCatalog.getString("Field too long");},
		getMinLettersText: function(minLettersValue) { return gettextCatalog.getString("The field must contain at least {{value}} letters.", {value: minLettersValue || 2});},
		getMinDigitsText: function(minDigitsValue) { return gettextCatalog.getString("The field must contain at least {{value}} digits.", {value: minDigitsValue || 2});}
    }
});

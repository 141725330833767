angular.module('uti.skayo.onlinesale').controller('LoginController', function (LoginService, $scope, $location, blockUI, SweetAlert, gettextCatalog) {
    var loginService = LoginService;
    $scope.email = '';
    $scope.password = '';
    $scope.rememberLastLogin = false;

    $('nav').show();
    $('footer').show();

    var previousEmail = loginService.previousEmail();
    if (previousEmail !== null) {
        $scope.rememberLastLogin = true;
        $scope.email = previousEmail;
    }

        var elem = document.getElementById("tut1");
    $scope.openFullscreen1 = function () {
        if (elem.requestFullscreen) {
            elem.play();
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.play();
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem.play();
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.play();
            elem.msRequestFullscreen();
        }
    };

    var elem2 = document.getElementById("tut2");
    $scope.openFullscreen2 = function () {
        if (elem2.requestFullscreen) {
            elem2.play();
            elem2.requestFullscreen();
        } else if (elem2.mozRequestFullScreen) { /* Firefox */
            elem2.play();
            elem2.mozRequestFullScreen();
        } else if (elem2.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem2.play();
            elem2.webkitRequestFullscreen();
        } else if (elem2.msRequestFullscreen) { /* IE/Edge */
            elem2.play();
            elem2.msRequestFullscreen();
        }
    };

    var elem3 = document.getElementById("tut3");
    $scope.openFullscreen3 = function () {
        if (elem3.requestFullscreen) {
            elem3.play();
            elem3.requestFullscreen();
        } else if (elem3.mozRequestFullScreen) { /* Firefox */
            elem3.play();
            elem3.mozRequestFullScreen();
        } else if (elem3.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem3.play();
            elem3.webkitRequestFullscreen();
        } else if (elem3.msRequestFullscreen) { /* IE/Edge */
            elem3.play();
            elem3.msRequestFullscreen();
        }
    };

    $scope.login = function () {
        blockUI.start();
        loginService.login($scope.email, $scope.password, $scope.rememberLastLogin, localStorage.getItem("skayo-client-language"))
            .then(function () {
                if (loginService.isSuperAdmin() === true) {
                    $location.path('/adminSearch');
                }
                else {
                    var url = new URL(window.location.href);

                    if (url.hash.indexOf("device=mobile") !== -1)
                        history.go(-1);
                    else
                        $location.path('/home');
                }
            },function(){
                SweetAlert.swal({
                        title: '',
                        text: gettextCatalog.getString('Wrong username or password'),
                        type: 'error'
                    });
            })
            .finally(function(){
                blockUI.stop();
            });
    };

    $scope.onKeyUp = function (event) { if (event.which === 13) $scope.login(); } // Login with enter key
});
angular.module('uti.skayo.onlinesale').service('LoginService', function ($cookies, $log, $http, $q, $location, $timeout) {
    var currentUser = null;
    var impersonatingUser = null;
    var self = this;

    this.login = function (email, password, rememberLastLogin, language) {
        var deferred = $q.defer();
        $log.info('Trying to log in as ' + email + ' (Remember Me: ' + rememberLastLogin + ')');

        $cookies.remove('impersonate');
        $cookies.remove('previousLogin');
        $cookies.remove('login');

        $http.post('api/login', { email: email, password: password, rememberLastLogin: rememberLastLogin, language: language })
            .success(function (data) {
                var loginExpires = new Date();
                var rememberExpires = new Date();
                if (rememberLastLogin) {
                    rememberExpires.setMonth(rememberExpires.getMonth() + 6);
                }
                loginExpires.setMinutes(loginExpires.getMinutes() + 30);
                
                if (data) {
                    $log.info('Logged in as ' + email);
                    $cookies.put('previousLogin', JSON.stringify(data), { expires: rememberExpires });
                    $cookies.put('login', JSON.stringify(data), { expires: loginExpires });
                    deferred.resolve(data);
                }
                deferred.reject();
                //TODO: check if received data is correct, and contains desired fields (email, etc...)
            })
            .error(function (data, status, headers, config) {
                deferred.reject('Error! ' + data);
            });
        return deferred.promise;
    };

    this.loginByProxy = function (adminEmail, userEmail) {
        sessionStorage.removeItem('updatedPostalAddress');
        var deferred = $q.defer();
        self.getCurrentUser();

        $log.info(currentUser.Email + ' attempting to impersonate ' + userEmail);
        $cookies.remove('impersonate');

        $http.post('api/Login/Proxy', { adminEmail: adminEmail, userEmail: userEmail })
            .success(function (data) {
                var loginExpires = new Date();
                loginExpires.setMinutes(loginExpires.getMinutes() + 30);

                if (data) {
                    $log.info(currentUser.Email + ' successfully impersonating ' + userEmail);
                    $cookies.put('impersonate', JSON.stringify(data), { expires: loginExpires });
                    deferred.resolve(data);
                }
                deferred.reject();
            })
            .error(function (data, status, headers, config) {
                deferred.reject('Error! ' + data);
            });
        return deferred.promise;
    };

    this.refreshAuthCookie = function (cookieName, userObject) {
        var loginExpires = new Date();

        $cookies.remove(cookieName);
        loginExpires.setMinutes(loginExpires.getMinutes() + 30);
        $cookies.put(cookieName, JSON.stringify(userObject), { expires: loginExpires });
    }

    this.updateImpersonation = function (userEmail) {
        self.getCurrentUser();
        if (self.getImpersonatingUser() !== null)
        {
            $log.info(currentUser.Email + ' updated impersonated user\'s email: ' + userEmail);
            impersonatingUser.Email = userEmail;
            self.refreshAuthCookie('impersonate', impersonatingUser);
        }
    };

    this.validateAuthentication = function () {
        $log.info('Validating authentication...');
        var user = self.getCurrentUser();
        var proxy = self.getImpersonatingUser();

        return ($q.when($http.post('api/Login/Validate', {
                    email: user ? user.Email : null,
                    password: '',
                    rememberLastLogin: false
                }).then(function (response) {
                    if (response.data) {
                        $log.info('Authentication valid. Refreshing cookies.');
                        self.refreshAuthCookie('login', user);
                        if (proxy) {
                            self.refreshAuthCookie('impersonate', proxy);
                        }
                        return $q.when(true);
                    } else {
                        $log.info('Authentication invalid. Removing cookies.');
                        $cookies.remove('login');
                        if (proxy) {
                            $cookies.remove('impersonate');
                        }
                        return $q.when(false);
                    }
                }))
        );
    };

    this.logout = function () {
        if (!self.isImpersonating())
        {
            currentUser = null;
            $cookies.remove('login');
            $location.path('/login');
            $http.post('api/Login/Logout');
        }
        else
        {
            impersonatingUser = null;
            $cookies.remove('impersonate');
            $location.path('/adminSearch');
            $http.post('api/Login/ProxyLogout');
        }

        sessionStorage.removeItem('updatedPostalAddress');
    };

    this.getCurrentUser = function () {
        currentUser = null;
        var user = $cookies.get('login');
        if (user) {
            currentUser = JSON.parse(user);
        }
        return currentUser;
    };

    this.getImpersonatingUser = function () {
        impersonatingUser = null;
        var user = $cookies.get('impersonate');
        if (user) {
            impersonatingUser = JSON.parse(user);
        }
        return impersonatingUser;
    };

    this.getActiveUser = function () {
        var user = self.getImpersonatingUser();
        return user === null ? self.getCurrentUser() : user;
    };

    this.isLoggedIn = function () {
        return self.getCurrentUser() !== null;
    };

    this.previousEmail = function () {
        previousUser = null;
        var user = $cookies.get('previousLogin');
        if (user) {
            previousUser = JSON.parse(user);
        }

        if (previousUser !== null && previousUser.RememberLogin === true) {
            return previousUser.Email;
        } else {
            return null;
        }
    };

    this.isSuperAdmin = function () {
        return self.getCurrentUser() !== null && currentUser.IsSuperAdmin === true;
    };

    this.isAdmin = function () {
        return self.getCurrentUser() !== null && currentUser.IsAdmin === true;
    };

    this.isImpersonating = function () {
        return self.getImpersonatingUser() !== null;
    };

    this.allowAdminActions = function () {
        return self.getCurrentUser() !== null && currentUser.AllowAdminActions === true;
    };

    this.getPostalAddress = function () {
        var address = sessionStorage.getItem('updatedPostalAddress');
        if (address)
            return address;

        return self.getActiveUser().PostalAddress;
    };

    this.updatePostalAddress = function (address, callback) {
        sessionStorage.setItem('updatedPostalAddress', address);

        $http
            .post('api/myAccount/postaladdress', { PostalAddress: address })
            .then(callback);
    };
});


angular.module('uti.skayo.onlinesale').service('Http401Interceptor', function ($rootScope, $location, $cookies, $q) {
    var service = this;

    service.request = function (config) {
        return config;
    };

    service.responseError = function (response) {
        if (response.status === 401) {
            $cookies.remove('login');
            $location.path('/login');
        }
        return $q.reject(response);
    };
});


//
// If the user is not logged in, and if they are not already on a public page (login, register), 
// then redirect them to the login page.
//
angular.module("uti.skayo.onlinesale").run(function ($rootScope, $window, $route, $location, $q, LoginService) {
    var loginService = LoginService;

    var noAuthenticationRequired = ['/login', '/register', '/contact', '/faq', '/recoverPassword', '/recoverPasswordConfirm', '/infoTransport'];
    var publicRoute = function (route) {
        var isPublic = false;
        noAuthenticationRequired.forEach(function (item, index) {
            isPublic = route === item ? true : isPublic;
        });
        return isPublic;
    };

    loginService.validateAuthentication().then(function (response) {
        var authenticated = response === null || typeof (response) === "undefined" ? false
                : typeof (response) === "object" ? response.data
                : response;

        if ($location.url() === "/contact" || $location.url() === "/faq"
            || $location.url() === "/recoverPassword"
            || $location.url().includes("/recoverPasswordConfirm")
            || $location.url().includes("/registerConfirm")
            || $location.url().includes("/infoTransport")
            || $location.url() === "/construction"
            || $location.url() === "/fines")
          
            return;

        var authRequired = !publicRoute($location.url());
        if (authRequired && !authenticated) {
            $q.when($location.path("/login"));
        } else if (authenticated && !authRequired) {
            event.preventDefault();
            if (!loginService.isSuperAdmin()) {
                $q.when($location.path("/home"));
            }
            else {
                $q.when($location.path("/adminSearch"));
            }
        }
        else if (authenticated && !loginService.isAdmin() && next.templateUrl.indexOf("admin") > -1) {
            event.preventDefault();
            loginService.logout();
        }
    });

    $rootScope.$on("$routeChangeStart", function (event, next, current) {
        loginService.validateAuthentication().then(function (response) {
            var authenticated = response === null || typeof(response) === "undefined" ? false 
                    : typeof(response) === "object" ? response.data 
                    : response;

            if ($location.url() === "/contact" || $location.url() === "/faq" 
                || $location.url() === "/recoverPassword"
                || $location.url().includes("/recoverPasswordConfirm")
                || $location.url().includes("/registerConfirm")
                || $location.url() === "/construction"
                || $location.url() === "/fines")
                //|| $location.url() === "/payment_rejected")
                return;

            var authRequired = !publicRoute($location.url());
            if (authRequired && !authenticated) {
                $q.when($location.path("/login"));
            } else if (authenticated && !authRequired) {
                event.preventDefault();
                if (!loginService.isSuperAdmin()) {
                    $q.when($location.path("/home"));
                }
                else {
                    $q.when($location.path("/adminSearch"));
                }
            }
            else if (authenticated && !loginService.isAdmin() && next.templateUrl.indexOf("admin") > -1) {
                event.preventDefault();
                loginService.logout();
            }
        });
    });
});
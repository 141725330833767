angular.module('uti.skayo.onlinesale').controller('HomeController', function ($scope, $http, $location) {
    $http.get('api/home').success(function (data) {
        $scope.validSession = data;
    });


    $('.container-fluid').removeClass('container-fluid').addClass('container').css({
            'padding-bottom': '',
            'padding-right': '15px',
            'padding-left': '15px'
    });
   
    $('nav').show();
    $('footer').show();

    var elem = document.getElementById("tut1");
    $scope.openFullscreen1 = function () {
        if (elem.requestFullscreen) {
            elem.play();
            elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) { /* Firefox */
            elem.play();
            elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem.play();
            elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { /* IE/Edge */
            elem.play();
            elem.msRequestFullscreen();
        }
    };

    var elem2 = document.getElementById("tut2");
    $scope.openFullscreen2 = function () {
        if (elem2.requestFullscreen) {
            elem2.play();
            elem2.requestFullscreen();
        } else if (elem2.mozRequestFullScreen) { /* Firefox */
            elem2.play();
            elem2.mozRequestFullScreen();
        } else if (elem2.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
            elem2.play();
            elem2.webkitRequestFullscreen();
        } else if (elem2.msRequestFullscreen) { /* IE/Edge */
            elem2.play();
            elem2.msRequestFullscreen();
        }
    };

   
    $scope.downloadFile = function (downloadPath) {
       
        window.open(downloadPath, '_blank', '');
    };
});
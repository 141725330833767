angular.module('uti.skayo.onlinesale').controller('RechargePurseController',function($scope, $uibModalInstance, application, card, gettextCatalog, $http, $filter){

    $scope.application = application;
    $scope.card = card;
    $scope.quantity = null;
    $scope.rechargeDate =  new Date();
    //set date of tomorrow
    $scope.rechargeDate.setDate($scope.rechargeDate.getDate() + 1);
    $scope.validationMessage = '';

    $scope.ok = function () {
        if ($scope.isValid() == false)
            return;

        $uibModalInstance.dismiss(false);

        var clientLanguage = gettextCatalog.getCurrentLanguage() || "EN";
        var rechargeInfo = {cardIdentifier: card.PhysicalSerialNo, applicationIndex :application.Index, quantity:$scope.quantity, clientLanguage:clientLanguage};

        $http.post('api/rechargeValidation',rechargeInfo).then(function(){
            post('api/recharge', rechargeInfo);
        });

    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss(false);
    };

    $scope.isValid = function(){
        if (!$scope.quantity){
            $scope.validationMessage = gettextCatalog.getString('Please enter an amount');
            return false;
        }

        if ((($scope.quantity*100) % 1) != 0 || $scope.quantity <= 0){
            $scope.validationMessage = gettextCatalog.getString('Invalid amount');
            return false;
        }

        if ($scope.quantity>application.MaxReloadQuantity){
            $scope.validationMessage = gettextCatalog.getString('Maximum value for a recharge is {{maxReloadQuantity}}', {maxReloadQuantity: $filter('number')(application.MaxReloadQuantity)});
            return false;
        };

        if (parseInt($scope.quantity) + application.CurrentBalanceCount > application.MaxQuantity){
            $scope.validationMessage = gettextCatalog.getString('Maximum total value cannot be bigger than {{maxQuantity}}. With the current recharge, it will be {{invalidQuantity}}',
                {
                    maxQuantity: $filter('number')(application.MaxQuantity),
                    invalidQuantity: $filter('number')($scope.quantity + application.CurrentBalanceCount)
            });
            return false;
        }


        $scope.validationMessage = '';
        return true;
    }




    //Method to post with redirect (full form post, not ajax...)
    var post = function(path, params, method) {
        method = method || "post"; // Set method to post by default if not specified.

        // The rest of this code assumes you are not using a library.
        // It can be made less wordy if you use one.
        var form = document.createElement("form");
        form.setAttribute("method", method);
        form.setAttribute("action", path);

        for(var key in params) {
            if(params.hasOwnProperty(key)) {
                var hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);

                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    }



    $scope.getCurrentBalanceText = function (item) {
        if (item.CurrentBalanceInfo && item.CurrentBalanceInfo.Format) {
            return gettextCatalog.getString(item.CurrentBalanceInfo.Format, item.CurrentBalanceInfo.Scope || null);
        }
        else {
            return item.CurrentBalance;
        }
    }

    $scope.getTotalPrice = function () {
        return $scope.application.Price * $scope.quantity;
    }
});
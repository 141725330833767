angular.module('uti.skayo.onlinesale').controller('FaqController',
    function() {

        var url = new URL(window.location.href);
        if (url.hash.indexOf("device=mobile") !== -1) {
            $('nav').addClass('hidden-xs');
        }
        else {
            $('nav').removeClass('hidden-xs');
        }

    });

var minDigits = function() {
	var allDigits ={};
	for(var i=0;i<=9;i++) {
		allDigits[''+i] = true;
	}
    return {
		restrict: "A",
        require: "ngModel",
		scope: false,
        link: function(scope, element, attributes, ngModel) {
			var minDigitsValue = Number(attributes.minDigits);
			minDigitsValue = isFinite(minDigitsValue) ? minDigitsValue : 3;
            ngModel.$validators.minDigits = function(modelValue) {
                if(!attributes.minDigits || angular.isUndefined(modelValue) || modelValue === "" || modelValue === null) {
					return true;
				}
				var sValue = modelValue + "";
				var count =0;
				for(var i=0;i<sValue.length;i++) {
					var c = sValue.charAt(i);
					if(allDigits[c]) {
						++count;
					}
					
					if(count >= minDigitsValue) {
						return true;
					}
				}
				
				return false;
				
            };
        }
    };
};

angular.module('uti.skayo.onlinesale').directive("minDigits", minDigits);

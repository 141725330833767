angular.module('uti.skayo.onlinesale').controller('AdminSalesController', function ($scope, $http) {
    $scope.salesOverview = true;
    $scope.moreDetails = false;

    // Sales Overview 
    $scope.sales = {};
    $scope.sales.productList = [];
    $scope.sales.data = [];
    $scope.sales.filter = "";
    $scope.sales.yearRange = [];
    $scope.sales.maxYears = 10;
    
    var saveOptions = Highcharts.getOptions().exporting.buttons.contextButton.menuItems;

    for (var i=0; i<saveOptions.length; i++) {
        if (saveOptions[i].textKey !== undefined && saveOptions[i].textKey.slice(-3) === "SVG") {
            saveOptions.splice(i, 1);
            break;
        }
    }

    $scope.sales.chartOptions = {
        chart: {
            plotBorderWidth: 0
        },
        title: {
            text: '<span translate>Vanzari lunare</span>',
        },
        subtitle: {
            text: '<span translate>Toate produsele</span>'
        },
        xAxis: {
            categories: [
                '<span translate>Jan</span>',
                '<span translate>Feb</span>',
                '<span translate>Mar</span>',
                '<span translate>Apr</span>',
                '<span translate>May</span>',
                '<span translate>Jun</span>',
                '<span translate>Jul</span>',
                '<span translate>Aug</span>',
                '<span translate>Sep</span>',
                '<span translate>Oct</span>',
                '<span translate>Nov</span>',
                '<span translate>Dec</span>'
            ],
            crosshair: true
        },
        yAxis: {
            className: 'highcharts-color-0',
            min: 0,
            title: {
                text: '<span translate>Sales Amount</span>'
            }
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            column: {
                pointPadding: 0.1,
                borderWidth: 0
            },
            series: {
                shadow: true,
                cursor: 'pointer',
                events: {
                    click: function (event) {
                        var fromDate = new Date($scope.sales.currentYear, event.point.index, 1, 0, 0, 0, 0);
                        var toDate = new Date($scope.sales.currentYear, event.point.index + 1, 1, 23, 59, 59, 999);
                        toDate.setDate(toDate.getDate() - 1);
                        $('#details-tab')[0].click();
                        $scope.moreDetails = true;
                        $scope.StartDate = fromDate;
                        $scope.EndDate = toDate;
                        $scope.search();
                    }
                }
            }
        },
        series: [{
            name: $scope.sales.filter,
            data: $scope.sales.data
        }],
        credits: {
            enabled: false
        },
        exporting: {
            buttons: {
                contextButton: {
                    menuItems: saveOptions
                }
            }
        }
    };

    $scope.sales.chartOptions.chart.renderTo = 'salesOverview';
    $scope.sales.chartOptions.chart.type = 'column';
    
    $scope.fillProductList = function (productYear) {
        var currentProduct = $scope.sales.filter === null || $scope.sales.filter === "" ? 0 : $scope.sales.filter.ProductId;
        $http
            .post('api/adminSales/ProductList', { ProductYear: productYear })
            .then(function (result)
            {
                $scope.sales.productList = result.data;
                
                var prod;
                for (var i = 0; i < $scope.sales.productList.length; i++) {
                    if ($scope.sales.productList[i].ProductId === currentProduct) {
                        prod = $scope.sales.productList[i];
                        break;
                    }
                }

                if (prod === undefined && ($scope.sales.filter === null || $scope.sales.filter === "")) {
                    $scope.sales.getData(productYear);
                }
                else {
                    $scope.sales.filter = prod === undefined ? "" : prod;
                }
            })
            .catch(function (error)
            {
                $scope.sales.productList = [];
                $scope.sales.filter = "";
            });
    };

    $scope.sales.currentYear = (new Date()).getFullYear() - 1; // Start the report based on last year.
    $scope.fillProductList($scope.sales.currentYear);

    $scope.sales.setYear = function (n) {
        if (n >= $scope.sales.firstYear && n <= $scope.sales.lastYear) {
            $scope.sales.currentYear = n;
        }
    };

    $scope.sales.displayYears = function () {
        var rangeSize = $scope.sales.maxYears;
        var totalYears = 1 + +$scope.sales.lastYear + -$scope.sales.firstYear;
        var startYear = $scope.sales.firstYear;
        var currentYear = $scope.sales.currentYear;
        var endYear = $scope.sales.lastYear;
        var range = [];

        rangeSize = (totalYears < rangeSize) ? totalYears : rangeSize;
        startYear = (currentYear > endYear - rangeSize + 1) ? endYear - rangeSize + 1 :
                    (currentYear < startYear + rangeSize) ? startYear : currentYear;

        for (var i = startYear; i < startYear + rangeSize; i++) {
            range.push(i);
        }
        return range;
    };

    $scope.$watch("sales.yearRange", function (newValue, oldValue) {
        var salesYears = $scope.sales.yearRange.length;
        if (salesYears > 0) {
            $scope.sales.firstYear = $scope.sales.yearRange[0].Year;
            $scope.sales.lastYear = $scope.sales.yearRange[salesYears - 1].Year;
        }
        else {
            $scope.sales.firstYear = $scope.sales.currentYear;
            $scope.sales.lastYear = $scope.sales.currentYear;
        }
    });

    $scope.$watch("sales.currentYear", function (newValue, oldValue) {
        if (typeof oldValue !== 'undefined' && oldValue !== newValue) {
            $scope.fillProductList(newValue);
        }
    });

    $scope.$watch("sales.filter", function (newValue, oldValue) {
        if (typeof oldValue !== 'undefined' && oldValue !== newValue) {
            $scope.sales.getData($scope.sales.currentYear);
        }
        $scope.sales.displayYears();
    });

    $scope.sales.getData = function (year) {
        var productId = $scope.sales.filter === null || $scope.sales.filter === "" ? 0 : $scope.sales.filter.ProductId;
        $http
            .post('api/adminSales/GetSalesData', {
                CurrentYear: year,
                ProductId: productId
            })
            .then(function (result) {
                $scope.sales.data = result.data.SalesInfo;
                $scope.sales.yearRange = result.data.YearRange;
                $scope.sales.chartOptions.series[0].name = $scope.sales.filter !== null && $scope.sales.filter !== "" ? $scope.sales.filter.Name : '<span translate>All Products</span>';
                $scope.sales.chartOptions.subtitle.text = $scope.sales.filter !== null && $scope.sales.filter !== "" ? $scope.sales.filter.Name : '<span translate>All Products</span>';
                $scope.sales.chartOptions.series[0].data = $scope.sales.data;
                var chart1 = new Highcharts.Chart($scope.sales.chartOptions);
            })
            .catch(function (error) { $scope.sales.data = null; $scope.sales.yearRange = []; });
    };

    // Sales Details
    $scope.TransactionList = null;

    // Date Range Functionality
    $scope.setInitialDateRange = function () {
        var fromDate = new Date();
        var toDate = new Date();

        fromDate.setDate(fromDate.getDate() - 7);
        fromDate.setHours(0, 0, 0, 0);
        $scope.StartDate = fromDate;
        toDate.setHours(23, 59, 59, 999);
        $scope.EndDate = toDate;
    }
    $scope.setInitialDateRange();

    $scope.dateOptions = {
        maxDate: new Date(),
        showWeeks: false,
    };

    $scope.endDatePopupOpen = function () {
        $scope.endDatePopup.opened = true;
    };

    $scope.startDatePopupOpen = function () {
        $scope.startDatePopup.opened = true;
    };

    $scope.startDatePopup = {
        opened: false
    };

    $scope.endDatePopup = {
        opened: false
    };

    $scope.clearOverview = function () {
        $scope.salesOverview = true;
        $scope.sales.currentYear = (new Date()).getFullYear() - 1;
        $scope.moreDetails = false;
    };

    $scope.backToOverview = function () {
        $scope.salesOverview = true;
        $scope.moreDetails = false;
    };

    $scope.clearDetails = function () {
        $scope.salesOverview = false;
        $scope.TransactionList = null;
        $scope.setInitialDateRange();
        $scope.moreDetails = false;
    };

    $scope.getDateString = function (dateObject, beginning) {
        var day = dateObject.getDate();       
        var month = dateObject.getMonth() + 1;
        var year = dateObject.getFullYear();

        return (day < 10 ? "0" + day : day) + "/" 
            + (month < 10 ? "0" + month : month) + "/" 
            + year 
            + (beginning === true ? " 00:00:00" : " 23:59:59");
    }

    $scope.search = function () {
        var startDateString = $scope.getDateString($scope.StartDate, true);
        var endDateString = $scope.getDateString($scope.EndDate, false);

        $http
            .post('api/adminSales/GetTransactionList', {
                StartDate: startDateString,
                EndDate: endDateString
            })
            .then(function (result) { 
                $scope.TransactionList = result.data.Transactions; 
                $scope.transactionCount = result.data.Transactions.length;
                $scope.totalAmount = result.data.Total;
                $scope.avgAmount = result.data.Total / $scope.transactionCount
                $scope.amountCurrency = result.data.Currency;
            })
            .catch(function (error) {
                $scope.clearDetails();
            });
    };
})

angular.module('uti.skayo.onlinesale').controller('ConfirmController', function ($scope, $uibModalInstance, message) {

    $scope.message = message;

    $scope.ok = function () {
        $uibModalInstance.close(true);
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss(false);
    };
});
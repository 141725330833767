angular.module('uti.skayo.onlinesale').controller('AdminSettingsController', function ($scope, $http, $location, blockUI, SweetAlert) {
    $scope.config = null;
    $scope.errors = null;

    $scope.loadSettings = function () {
        blockUI.start();
        $http.get('api/adminGetSettings')
             .then(function (data) {
                 blockUI.stop();
                 $scope.config = data.data;

                 if ($scope.form)
                     $scope.form.$setPristine();
             })
             .catch(function (error) { blockUI.stop(); $scope.errors = ['Communication error !'] });
    }

    $scope.loadSettings();

    $scope.save = function () {
        blockUI.start();
        $http.post('api/adminSaveSettings', $scope.config)
             .then(function (data) {
                 blockUI.stop();
                 if (data.data.Errors.length === 0)
                     $scope.loadSettings();
                 else
                     $scope.errors = data.data.Errors;
             })
             .catch(function (error) { blockUI.stop(); $scope.errors = ['Communication error !']; });
    }

    $scope.create = function () {
        $location.path('/adminSettingCreate');
    }
})
angular.module('uti.skayo.onlinesale').controller('MyCardsController',function(LoginService, $scope, $http, $uibModal, SweetAlert, $routeParams, gettextCatalog, $location){
    var loginService = LoginService;

    $scope.cards = [];
    $scope.loaded = false;

    var url = new URL(window.location.href);
    if (url.hash.indexOf("device=mobile") !== -1) {
        $('nav').addClass('hidden-xs');
    }
    else {
        $('nav').removeClass('hidden-xs');
    }

    var isImpersonating = loginService.isImpersonating();
    $scope.globalRechargeOptions = (loginService.allowAdminActions() && isImpersonating) || !isImpersonating;

    if($routeParams.paymentResult) {
 	var result = JSON.parse($routeParams.paymentResult);
	if(result) {
		if(result.Success){

            var text = gettextCatalog.getString("PAYMENT_SUCCESS", result);

		 	SweetAlert.swal({
               		    title: gettextCatalog.getString('Success'),
	                    text: text,
        	            type: "success"},
                	    function(){
                        	//redirect to login
	                       $location.url($location.path());
        	            }
	            );
		}
		if(!result.Success) {
			var text = "";
			if(result.ErrorCode) {
				  text = gettextCatalog.getString("Error_" + result.ErrorCode, result);
			}
			else {
				var text = gettextCatalog.getString("An error was encountered in processing the payment. Please try again later.");
			}

			SweetAlert.swal({
               		    title: "",
	                    text: text,
        	            type: "error"},
                	    function(){
                        	//redirect to login
	                       $location.url($location.path());
        	            }
	            );
		}
	return;
	}
    }

    $scope.getLocalizationText = function (localization, language) {
        if (!localization)
            return null;
        var text = null;
        for (var i = 0; i < localization.length; i++) {
            if (localization[i].Language == language) {
                text = localization[i].Text;
                break;
            }
        }
        return text;
    }

    $scope.getAppName = function(app){
        var language = gettextCatalog.getCurrentLanguage().replace('_','-');
        if (app.Localization == null) {
            return "";
        }
        var text = $scope.getLocalizationText(app.Localization, language);
        if (!text)
            text = $scope.getLocalizationText(app.Localization, "Default");
        return text || app.Name;
    };

    $http.get('api/myCards').success(function (data) {
       $scope.loaded = true;
       $scope.cards = data;
        //filter special dates (1900-01-01T00:00:00) (ugly)
        for (var i=0;i<$scope.cards.length; i++){
            var card = $scope.cards[i];
            for (var j=0;j<card.Applications.length;j++){
                var application = card.Applications[j];
                if (application.LastReloadDate == '1900-01-01T00:00:00')
                    application.LastReloadDate = '';
                if (application.LastValidationDate == '1900-01-01T00:00:00')
                    application.LastValidationDate= '';


                if (application.IsPass && application.TransportApplication != null){
                    if (!application.TransportApplication.HasValidPeriods){
                        application.Sold = gettextCatalog.getString('Expired');
                    }
                    else {
                        var validPeriods = application.TransportApplication.ValidPeriods.length;

                    }
                    application.Sold = application.CurrentBalance;
                }
                else if (application.IsPurse){
                    application.Sold = application.CurrentBalance;
                }
            }
        }
    }); 

    $scope.recharge = function(app, card) {
        var controller = "";
        var templateUrl = "";

        //decide what interface to show
        if (app.IsPurse){
            templateUrl = '/app/views/rechargePurse.html';
            controller = 'RechargePurseController';
        }
        else if (app.IsPass){
            templateUrl = '/app/views/rechargePass.html';
            controller = 'RechargePassController';
        }
        else if (app.IsTrip){
            templateUrl = '/app/views/rechargeTrip.html';
            controller = 'RechargeTripController';
        }


        var modalInstance = $uibModal.open({
            templateUrl: templateUrl,
            //size: 'lg',
            windowClass: 'center-modal',
            controller: controller,
            resolve:{
                application: app,
                card: card
            }
        });
    }

    $scope.allowRecharge = function (app, card) {
        if (!card.AllowedAppKeys)
            return false;

        var supportedApp = card.AllowedAppKeys.indexOf(app.ApplicationKey) !== -1;
        return app.ShowRechargeOption && supportedApp;
    }

    $scope.blockCard = function(cardLogicalSerial)
    {
        swal({
            title: gettextCatalog.getString("Block card"),
            text: gettextCatalog.getString("Blocking the card can be useful when it gets stolen or lost. The remaining amount can be translated at a point of sale."),
            type: "input",
            inputPlaceholder: gettextCatalog.getString("Reason"),
            showCancelButton: true,
            showLoaderOnConfirm: true,
            closeOnConfirm: false
        }, function(inputValue){
            if (inputValue === false)
                return false;
            if (inputValue === "") {
                swal.showInputError(gettextCatalog.getString("A reason is needed"));
                return false
            }
            $http.post('api/myCards/block',{CardLogicalSerial: cardLogicalSerial, Reason: inputValue}).then(function(){
                swal(gettextCatalog.getString("The card has been successfully blocked"));
                $location.url($location.path()); //hope this works (it should refresh the page?
            });

        });
    }

    $scope.getCurrentBalanceText = function (item) {
        if (item.CurrentBalanceInfo && item.CurrentBalanceInfo.Format) {
            return gettextCatalog.getString(item.CurrentBalanceInfo.Format, item.CurrentBalanceInfo.Scope || null);
        }
        else {
            return item.CurrentBalance;
        }
    }

  
});

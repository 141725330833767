angular.module('uti.skayo.onlinesale').controller('MyAccountController',function($scope, $http, gettextCatalog, LoginService, SweetAlert){
    var loginService = LoginService;

    $scope.message = "";

    $scope.oldPassword = "";
    $scope.password = "";
    $scope.passwordConfirm = "";

    $scope.passwordForEmail = '';
    $scope.user = loginService.getActiveUser(); // CurrentUser() or ImpersonatingUser() (as relevant)
    $scope.savedAdminRights = $scope.user.IsAdmin;
    $scope.savedEmailAddress = $scope.user.Email;

    $scope.postalAddress = loginService.getPostalAddress();
    $scope.subscriptions = [];


    $http.get('api/myAccount/subscriptions').then(function(msg) {
        $scope.subscriptions = msg.data;
        /*for (var i=0;i<msg.data.length;i++){
            $scope.subscriptions[i].Description =gettextCatalog.getString($scope.subscriptions[i].Description);
        }*/
    });

    $http.get('api/myAccount/getPersonName').success(function (data) {
        $scope.firstName = data.FirstName;
        $scope.lastName = data.LastName;
    }).error(function (errorMessage) {
        console.log(errorMessage);
    });

    $http.get('api/myAccount/getCardList').success(function (data) {
        console.log(data.Cards.join(', '));
    }).error(function (errorMessage) {
        console.log(errorMessage);
    });

    $scope.getTranslation = function(text){
        return gettextCatalog.getString(text);
    }

    $scope.clearPasswordFields = function () {
        $scope.oldPassword = "";
        $scope.password = "";
        $scope.passwordConfirm = "";
    }

    $scope.emptyPasswordFields = function () {
        return $scope.oldPassword == ""
            || $scope.password == ""
            || $scope.passwordConfirm == "";
    }

    $scope.changePassword = function () {
        $scope.form.$setSubmitted();
        if (!$scope.emptyPasswordFields() && $scope.form.$valid) {
            $http.post('api/myAccount/changePassword', { oldPassword: $scope.oldPassword, newPassword: $scope.password })
                .then(function () {
                    showSuccess(gettextCatalog.getString("The password has been successfully changed"));
                    $scope.clearPasswordFields();
                    $scope.form.$setUntouched();
                    $scope.form.$setPristine();
                });
        }
    };

    $scope.updatePersonName = function () {
        if ($scope.firstName != "" && $scope.firstName != undefined && $scope.lastName != "" && $scope.lastName != undefined) {
            $http.post('api/myAccount/updatePersonName', { firstName: $scope.firstName, lastName: $scope.lastName })
                .then(function() {
                    showSuccess(gettextCatalog.getString("First name and last name have been successfully changed"));
                });

        } 
    }

    $scope.saveInfo = function () {
        $scope.formInfo.$setSubmitted();
        if ($scope.user.Email != $scope.savedEmailAddress) {
            if ($scope.formInfo.$valid) {
                $http.post('api/myAccount/saveInfo', { newEmail: $scope.user.Email })
                   .then(function () {
                       showSuccess(gettextCatalog.getString("Success"));
                       $scope.formInfo.$setUntouched();
                       $scope.formInfo.$setPristine();
                       loginService.updateImpersonation($scope.user.Email);
                       $scope.savedEmailAddress = $scope.user.Email;
                   });
            }
        }
    };

    $scope.toggleAdminRights = function () {
        if ($scope.user.IsAdmin != $scope.savedAdminRights) {
            var confirmMessage = "Are you sure you want to ";
            confirmMessage += $scope.user.IsAdmin ? "Grant" : "Revoke";
            confirmMessage += " Administrator Rights for ";
            confirmMessage += $scope.user.Name;
            confirmMessage += "?";

            SweetAlert.swal({
                title: "Administrator Rights",
                text: confirmMessage,
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                closeOnConfirm: false,
                closeOnCancel: false
            },
            function (isConfirm) {
                if (isConfirm) {
                    swal("Change Verified", "Please save your changes.", "success");
                    $scope.formAdmin.$setDirty();
                } else {
                    swal("Change Cancelled", "", "error");
                    $scope.user.IsAdmin = $scope.savedAdminRights;
                    $scope.formAdmin.$setUntouched();
                    $scope.formAdmin.$setPristine();
                }
            });
        }
    };

    $scope.updateAdmin = function () {
        if ($scope.savedAdminRights != $scope.user.IsAdmin) {
            $http.post('api/myAccount/updateAdmin')
               .then(function () {
                   showSuccess(gettextCatalog.getString("Success"));
                   $scope.formAdmin.$setUntouched();
                   $scope.formAdmin.$setPristine();
                   $scope.savedAdminRights = $scope.user.IsAdmin;
               });
        }
    };

    $scope.updateNotifications = function(){
        $http.post('api/myAccount/subscriptions',$scope.subscriptions).then(function(){
            showSuccess(gettextCatalog.getString("Email notifications have been successfully updated"));
        })
    };

    $scope.validation = {
        getRequiredText : function(){ return gettextCatalog.getString("Required field"); },
        getInvalidEmailText : function() { return gettextCatalog.getString("Invalid email"); },
        getSameText: function() { return gettextCatalog.getString("This field needs to be the same as the previous one"); },
        getTooShortText : function(){ return gettextCatalog.getString("Field too short");},
        getTooLongText: function() { return gettextCatalog.getString("Field too long");},
		getMinLettersText: function(minLettersValue) { return gettextCatalog.getString("The field must contain at least {{value}} letters.", {value: minLettersValue || 2});},
        getMinDigitsText: function (minDigitsValue) { return gettextCatalog.getString("The field must contain at least {{value}} digits.", { value: minDigitsValue || 2 }); },
        agreeCheck: function () { return gettextCatalog.getString("You must agree terms and conditions"); }
    }

    $scope.isSuperAdmin = function () {
        return (loginService.isSuperAdmin() && !loginService.isImpersonating());
    };

    $scope.isImpersonating = function () {
        return (loginService.isImpersonating());
    };

    $scope.updatePostalAddress = function () {
        loginService.updatePostalAddress($scope.postalAddress, function () {
            showSuccess(gettextCatalog.getString("The address associated with your account has been successfully changed"));
        });
    }

    var showSuccess = function(text)
    {
        SweetAlert.swal({
                title: gettextCatalog.getString('Success'),
                text: text,
                type: "success"}
        );
    }

});
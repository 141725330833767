angular.module('uti.skayo.onlinesale').controller('ProductsController',function($scope, $http,gettextCatalog){
    $scope.products = []

    $http.get('api/products').success(function(data){
        $scope.products = data;
    });

    $scope.getLocalizationText = function (localization, language) {
        if (!localization)
            return null;
        var text = null;
        for (var i = 0; i < localization.length; i++) {
            if (localization[i].Language == language) {
                text = localization[i].Text;
                break;
            }
        }
        return text;
    }

    $scope.getProductName = function(product){
        var language = gettextCatalog.getCurrentLanguage().replace('_', '-');
        var localization = product.Localization;
        var text = $scope.getLocalizationText(localization, language);
        if (!text)
            text = $scope.getLocalizationText(localization, "Default");
        return text || product.Name;
    };

    $scope.getProductValidity = function(p){

        //for purse, there is no expiration date, return ''
        if (p.IsPurse){
            return '';
        }

        var count = p.Duration;
        var timeUnit = p.TimeUnit;
        var validityType = p.ValidityType;
        var text = "";
        if (validityType == "timeunit") {
            var pluralString = '{{$count}} ' + timeUnit;
            text = gettextCatalog.getPlural(count, timeUnit, pluralString, { $count: count });
        }

        if (validityType == "enddate") {
            var until = 'until';
            text = gettextCatalog.getString(until) + " " + p.EndDate;
        }

        if (validityType == "unlimited") {
            text = gettextCatalog.getString("unlimited");
        }
        return text;
    };
});
var minLetters = function() {
	var alphabet = 'abcdefghijklmnopqrstuvwxyz';
	var allLetters = {};
	for(var i=0;i<alphabet.length;i++) {
		allLetters[alphabet.charAt(i)] = true;
	}
    return {
		restrict: "A",
        require: "ngModel",
		scope: false,
        link: function(scope, element, attributes, ngModel) {
			var minValue = Number(attributes.minLetters);
			minValue = isFinite(minValue) ? minValue : 3;
            ngModel.$validators.minLetters = function(modelValue) {
                if(!attributes.minLetters || angular.isUndefined(modelValue) || modelValue === "" || modelValue === null) {
					return true;
				}
				var sValue = modelValue + "";
				var count =0;
				for(var i=0;i<sValue.length;i++) {
					var c = sValue.charAt(i).toLowerCase();
					if(allLetters[c]) {
						++count;
					}
					
					if(count >= minValue) {
						return true;
					}
				}
				
				return false;
				
            };
        }
    };
};

angular.module('uti.skayo.onlinesale').directive("minLetters", minLetters);

angular.module('uti.skayo.onlinesale').controller('RegisterConfirmController',function($scope, $routeParams, $http, gettextCatalog){
    //alert($routeParams.code);

    $scope.message = '';

    $http.get('api/register/'+$routeParams.code)
        .then(function(email){
            $scope.message = gettextCatalog.getString('Email confirmat cu succes. Va puteti loga pe pagina principala');
            //success
        }, function(response){
            //some error
            if (response.data && response.data.ExceptionMessage && response.data.ExceptionType == "Uti.Skayo.OnlineSale.DomainModel.RegistrationException"){
                $scope.message = gettextCatalog.getString(response.data.ExceptionMessage);
            }

        });
});

angular.module('uti.skayo.onlinesale').controller('InfoTransportController',
    function () {
       
        $('nav').hide();
        $('footer').hide();
        $('body').css('margin', '0');
        $('.container').removeClass('container').addClass('container-fluid').css({
            'padding-bottom': '',
            'padding-right': '0',
            'padding-left': '0'
        });
    });

/**
 * Created by Mike on 12.09.2015.
 */

angular.module('uti.skayo.onlinesale').controller('RegisterController', function (LoginService, $scope, $uibModal, $location, blockUI, $http, gettextCatalog,SweetAlert) {
    $scope.lastName = "";
    $scope.firstName = "";
    $scope.identification = "";
    $scope.cardId = "";
    $scope.email = "";
    $scope.password = "";
    $scope.passwordConfirm = "";
    $scope.captcha = "";
    $scope.captchaSrc = "api/captcha";

    $scope.countrySelect = {};
    $scope.countrySelect.CountryCode = "RO";

    $http.get('api/register/countries').success(function (data) {
        $scope.countrySelect.countries = data;
    });

    $scope.emptyRegistrationFields = function () {
        return $scope.lastName == ""
            || $scope.firstName == ""
            || $scope.identification == ""
            || $scope.cardId == ""
            || $scope.email == ""
            || $scope.password == ""
            || $scope.passwordConfirm == ""
            || $scope.captcha == ""
            || $scope.country == "";
    }

    $scope.register = function(){
        $scope.form.$setSubmitted();
        if (!$scope.emptyRegistrationFields() && $scope.form.$valid) {
            $http.post('api/register', {
                captcha: $scope.captcha,
                lastName: $scope.lastName,
                firstName: $scope.firstName,
                identification: $scope.identification,
                cardId: $scope.cardId,
                countryCode: $scope.countrySelect.CountryCode,
                email: $scope.email,
                password: $scope.password,
                language: localStorage.getItem("skayo-client-language")
            }).then(function (response) {
                SweetAlert.swal({
                    title: gettextCatalog.getString('Success'),
                    text: gettextCatalog.getString('Your registration has been saved. In order to confirm, you will receive an email.'),
                    type: "success"
                },
                        function () {
                            //redirect to login
                            $location.path('/login');
                        }
                );
            }, function (error) {
                $scope.captchaSrc = 'api/captcha?_ts=' + new Date().getTime();
            });
        }
    }
    

    $scope.showTerms = function () {
        var languageCode = "en_US";
        try {
            languageCode = gettextCatalog.getCurrentLanguage();
            if (languageCode == "ru")
                languageCode = "en_US";
        }
        catch(exception) {
            console.error(exception);
        }
        languageCode = languageCode || "en";
        var terms = '/app/views/terms_' + languageCode + ".html";
        var modalInstance = $uibModal.open({
            templateUrl: terms,
            size: 'lg'
        });
    }

    $scope.validation = {
        getRequiredText : function(){ return gettextCatalog.getString("Required field"); },
        getInvalidEmailText : function() { return gettextCatalog.getString("Invalid email"); },
        getSameText: function() { return gettextCatalog.getString("This field needs to be the same as the previous one"); },
        getTooShortText : function(){ return gettextCatalog.getString("Field too short");},
        getTooLongText: function() { return gettextCatalog.getString("Field too long");},
		getMinLettersText: function(minLettersValue) { return gettextCatalog.getString("The field must contain at least {{value}} letters.", {value: minLettersValue || 2});},
        getMinDigitsText: function (minDigitsValue) { return gettextCatalog.getString("The field must contain at least {{value}} digits.", { value: minDigitsValue || 2 }); },
        agreeCheck: function () { return gettextCatalog.getString("You must agree terms and conditions"); }
    }

});

angular.module('uti.skayo.onlinesale').controller('RecoverPasswordController', function($scope, $http, SweetAlert, gettextCatalog, $location){
    $scope.email = '';
    $scope.captchaSrc = "api/captcha";
    $scope.captcha = '';

    $scope.recover = function(){
        $http.post('api/passwordRecover',{email: $scope.email, captcha: $scope.captcha})
            .then(function(){
                SweetAlert.swal({
                    title: gettextCatalog.getString('Success'),
                    text: gettextCatalog.getString('Your registration has been saved. In order to confirm, you will receive an email.')
                },function(){
                    //redirect to login
                    $location.path('/login');
                });
            }, function(error){
                //refresh captcha
                $scope.captchaSrc = 'api/captcha?_ts=' + new Date().getTime();
            });
    }

});

angular.module('uti.skayo.onlinesale').controller('TransactionHistoryController', function ($scope, $http, $uibModal, gettextCatalog, SweetAlert) {
    $scope.TransactionList = [];

    var url = new URL(window.location.href);
    if (url.hash.indexOf("device=mobile") !== -1) {
        $('nav').addClass('hidden-xs');
    }
    else {
        $('nav').removeClass('hidden-xs');
    }

    $scope.getTransactions = function (pageNumber) {
        $http
            .post('api/transactionHistory/TransactionList', {
                PageNumber: pageNumber,
                ItemsPerPage: $scope.paging.itemsPerPage
            })
            .then(function (result) { $scope.TransactionList = result.data.Transactions; $scope.paging.totalItems = result.data.Total; })
            .catch(function (error) { $scope.TransactionList = null; $scope.paging.totalItems = 0; });
    };

    $scope.getStatus = function (item) {
        return gettextCatalog.getString(item.CurrentStatus);
    }

    $scope.getLocalizationText = function (localization, language) {
        if (!localization)
            return null;
        var text = null;
        for (var i = 0; i < localization.length; i++) {
            if (localization[i].Language === language) {
                text = localization[i].Text;
                break;
            }
        }
        return text;
    }

    $scope.getProductName = function(product){
        var language = gettextCatalog.getCurrentLanguage().replace('_','-');
        var localization = product.Localization;
        var text = $scope.getLocalizationText(localization, language);
        if (!text)
            text = $scope.getLocalizationText(localization, "Default");
        return text || product.Name;
    };

    $scope.showStatusHistory = function (transaction) {
        var modalInstance = $uibModal.open({
            templateUrl: '/app/views/transactionStatusHistory.html',
            windowClass: 'center-modal',
            controller: 'TransactionStatusHistoryController',
            resolve: {
                transaction: transaction
            }
        });
    }

    $scope.generateInvoice = function (orderNo) {
        $http
            .post('api/receipts/IsValidPostalAddress')
            .then(
                function (response) {
                    $http
                        .post('api/receipts/GenerateInvoice/' + orderNo)
                        .then(
                            function (response) {
                                $scope.showNotification('success', 'The invoice has been generated and can be downloaded');
                                $scope.getTransactions($scope.paging.currentPage);
                            },
                            function (response) {
                                switch (response.data.Message) {
                                    case 'AlreadyExists': $scope.showNotification('error', 'The invoice has already been generated'); break;
                                    case 'CannotGenerate': $scope.showNotification('error', 'The invoice could not be generated'); break;
                                    default:
                                        $scope.showNotification('error', 'Communication error !'); break;
                                }
                            });
                },
                function (error) {
                    $scope.showNotification('error', 'Please fill your postal address on "My account" page, in order to be able to generate the invoice');
                });
    }

    $scope.downloadInvoice = function (orderNo) {
        $http
            .post('api/receipts/IsValidPostalAddress')
            .then(
                function (response) {
                    $http
                        .post('api/receipts/CheckDownloadInvoice/' + orderNo)
                        .then(
                            function (response) {
                                window.location.href = '/api/receipts/DownloadInvoice/' + orderNo;
                            },
                            function (response) {
                                switch (response.status) {
                                    case 400: $scope.showNotification('error', 'The invoice has not been generated'); break;
                                    case 500:
                                    default:
                                        $scope.showNotification('error', 'Communication error !'); break;
                                }
                            });
                },
                function (error) {
                    $scope.showNotification('error', 'Please fill your postal address on "My account" page, in order to be able to generate the invoice');
                });
    }

    $scope.downloadReceipt = function (orderNo) {


                    $http
                        .post('api/receipts/CheckDownloadReceipt/' + orderNo)
                        .then(
                            function (response) {
                                window.location.href = '/api/receipts/DownloadReceipt/' + orderNo;
                            },
                            function (response) {
                                switch (response.status) {
                                case 400: $scope.showNotification('error', 'The receipt has not been generated'); break;
                                case 500:
                                default:
                                    $scope.showNotification('error', 'Communication error !'); break;
                                }
                            });
    }

    $scope.downloadProofOfPayment = function (orderNo) {


        $http
            .post('api/receipts/CheckProofOfPayment/' + orderNo)
            .then(
                function (response) {
                    window.location.href = '/api/receipts/DownloadProofOfPayment/' + orderNo;
                },
                function (response) {
                    switch (response.status) {
                    case 400: $scope.showNotification('Error', 'Error generating Proof o payment!'); break;
                    case 500:
                    default:
                        $scope.showNotification('Error', 'Communication error !'); break;
                    }
                });
    }
    $scope.showNotification = function (type, text) {
        SweetAlert.swal({
            title: '',
            text: gettextCatalog.getString(text),
            type: type
        });
    }

    // 
    // -- Paging Functionality --
    $scope.paging = {};
    $scope.paging.pageSizeList = [5, 10, 15, 20, 50];
    $scope.paging.itemsPerPage = 15;
    $scope.paging.maxSize = 10;

    $scope.paging.clearPaging = function () {
        $scope.paging.currentPage = 0;
        $scope.paging.totalItems = 0;
    }
    $scope.paging.clearPaging();

    $scope.paging.pageRange = function () {
        var rangeSize = $scope.paging.maxSize;
        var start = $scope.paging.currentPage;
        var pages = $scope.paging.totalPages();
        var range = [];

        rangeSize = (pages < rangeSize) ? pages : rangeSize;
        start = (start > pages - rangeSize) ? pages - rangeSize :
                    (start < rangeSize) ? 0 : start;

        for (var i = start; i < start + rangeSize; i++) {
            range.push(i);
        }
        return range;
    };

    $scope.paging.totalPages = function () {
        return Math.ceil($scope.paging.totalItems / $scope.paging.itemsPerPage);
    };

    $scope.paging.setPage = function (n) {
        if (n >= 0 && n < $scope.paging.totalPages()) {
            $scope.paging.currentPage = n;
        }
    };

    $scope.$watch("paging.currentPage", function (newValue, oldValue) {
        $scope.getTransactions(newValue);
    });

    $scope.$watch("paging.itemsPerPage", function (newValue, oldValue) {
        $scope.getTransactions(0);
    });

});
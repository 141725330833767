angular.module('uti.skayo.onlinesale').controller('TransactionStatusHistoryController', function ($scope, $http, $uibModalInstance, transaction, gettextCatalog) {
    $scope.items = null;

    $http.get('api/transactionHistory/' + transaction.SaleTransactionId).then(
        function(data) {
             $scope.items = data.data;
        },
        function (error) { $scope.items = null; }
    );

    $scope.closeDialog = function () {
        $uibModalInstance.dismiss(false);
    }
})
angular.module('uti.skayo.onlinesale').controller('AdminSearchController', function (LoginService, $scope, $http, $location) {
    var loginService = LoginService;

    $scope.Email = '';
    $scope.CNP = '';
    $scope.FirstName = '';
    $scope.LastName = '';
    $scope.OnlyAdmin = false;
    $scope.UserList = null;

    // 
    // -- Paging Functionality --
    $scope.paging = {};
    $scope.paging.pageSizeList = [5, 10, 15, 20, 50];
    $scope.paging.itemsPerPage = 15;
    $scope.paging.maxSize = 10;

    $scope.paging.clearPaging = function () {
        $scope.paging.currentPage = 0;
        $scope.paging.totalItems = 0;
    }

    $scope.paging.clearPaging();

    $scope.paging.pageRange = function () {
        var rangeSize = $scope.paging.maxSize;
        var start = $scope.paging.currentPage;
        var pages = $scope.paging.totalPages();
        var range = [];

        rangeSize = (pages < rangeSize) ? pages : rangeSize;
        start = (start > pages - rangeSize) ? pages - rangeSize :
                    (start < rangeSize) ? 0 : start;

        for (var i = start; i < start + rangeSize; i++) {
            range.push(i);
        }
        return range;
    };

    $scope.paging.totalPages = function () {
        return Math.ceil($scope.paging.totalItems / $scope.paging.itemsPerPage);
    };

    $scope.paging.setPage = function (n) {
        if (n >= 0 && n < $scope.paging.totalPages()) {
            $scope.paging.currentPage = n;
        }
    };

    $scope.$watch("paging.currentPage", function (newValue, oldValue) {
        if ($scope.paging.totalItems != 0) {
            $scope.search(newValue);
        }
    });

    $scope.$watch("paging.itemsPerPage", function (newValue, oldValue) {
        if ($scope.paging.totalItems != 0) {
            $scope.search(-1);
        }
    });

    $scope.search = function (pageNumber) {
        if (pageNumber < 0) {
            $scope.paging.clearPaging();
            pageNumber = 0;
        }
        $http
            .post('api/adminSearch/UserList', {
                Email: $scope.Email,
                CNP: $scope.CNP,
                FirstName: $scope.FirstName,
                LastName: $scope.LastName,
                OnlyAdmin: $scope.OnlyAdmin,
                PageNumber: pageNumber,
                ItemsPerPage: $scope.paging.itemsPerPage
            })
            .then(function (result) { $scope.UserList = result.data.Users; $scope.paging.totalItems = result.data.Total; })
            .catch(function (error) { $scope.UserList = null; $scope.paging.totalItems = 0; });
    };

    $scope.clear = function () {
        $scope.Email = '';
        $scope.CNP = '';
        $scope.FirstName = '';
        $scope.LastName = '';
        $scope.OnlyAdmin = false;
        $scope.UserList = null;
        $scope.paging.clearPaging();
    };

    $scope.impersonate = function (user) {
        $http
            .post('api/adminSearch/Impersonate', {
                Email: user.Email
            })
            .then(function (result) {
                var adminUser = loginService.getCurrentUser();
                loginService.loginByProxy(adminUser.Email, result.data.Email).then(function(){
                    $location.path('/myAccount');
                });
            })
            .catch(function (error) { debugger; });
    };
})
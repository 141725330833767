angular.module('uti.skayo.onlinesale').controller('RechargePassController',function($scope, $uibModalInstance, application, card, gettextCatalog,$http, $locale){


    Date.prototype.addDays = function(days) {
        this.setDate(this.getDate() + parseInt(days));
        return this;
    };

    Date.prototype.toShortDateString = function() {
        return this.getDate()+'.' + (this.getMonth()+1)+'.'+this.getFullYear();
    };

    $scope.validationMessage = '';
    $scope.message= 'Super';
    $scope.application = application;
    $scope.card = card;
    $scope.startDate = null;

    $scope.rechargeDate =  new Date().addDays(1);
    //set date of tomorrow
    //$scope.rechargeDate.setDate($scope.rechargeDate.getDate() + 1);
    $scope.validationMessage = '';


    if (application.MaxValidEndDate)
        $scope.minDate = new Date(application.MaxValidEndDate).addDays(1);
    else
        $scope.minDate = new Date().addDays(1);
    $scope.dateOptions = {
        minDate: new Date()
    };
    $scope.maxDate = new Date().addDays(60);
  
    $scope.formats = ['dd-MMMM-yyyy', 'yyyy/MM/dd', 'dd.MM.yyyy', 'shortDate'];
    $scope.format = $scope.formats[2];
    $scope.startDate = $scope.minDate;


    $scope.isValid = function(){
        if (!$scope.startDate){
            $scope.validationMessage = gettextCatalog.getString('Enter a valid date');
            return false;
        }
        if (application.MaxValidEndDate && $scope.startDate < new Date(application.MaxValidEndDate)){
            $scope.validationMessage = gettextCatalog.getString('The date cannot be before {{minDate}}',{minDate: $scope.minDate.toShortDateString()});
            return false;
        }

        var maxDate = new Date($scope.maxDate).addDays(1);
        if ($scope.startDate > maxDate ){
            $scope.validationMessage = gettextCatalog.getString('The date cannot be after {{maxDate}}',{maxDate: $scope.maxDate.toShortDateString()});
            return false;
        }

        $scope.validationMessage='';
        return true;
    }
   
    $scope.status = {
        opened: false
    };
    $scope.open = function ($event) {
        $scope.status.opened = true;
    };

    $scope.ok = function () {
        if (!$scope.isValid())
            return;
        $uibModalInstance.dismiss(false);

        var rechargeInfo = {cardIdentifier: card.PhysicalSerialNo, applicationIndex :application.Index, startDate:$scope.startDate.toISOString()};

        $http.post('api/rechargeValidation',rechargeInfo).then(function(){
            post('api/recharge', rechargeInfo);
        });

    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss(false);
    };



    //Method to post with redirect (full form post, not ajax...)
    var post = function(path, params, method) {
        method = method || "post"; // Set method to post by default if not specified.

        // The rest of this code assumes you are not using a library.
        // It can be made less wordy if you use one.
        var form = document.createElement("form");
        form.setAttribute("method", method);
        form.setAttribute("action", path);

        for(var key in params) {
            if(params.hasOwnProperty(key)) {
                var hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);

                form.appendChild(hiddenField);
            }
        }

        document.body.appendChild(form);
        form.submit();
    }



    $scope.getCurrentBalanceText = function (item) {
        if (item.CurrentBalanceInfo && item.CurrentBalanceInfo.Format) {
            return gettextCatalog.getString(item.CurrentBalanceInfo.Format, item.CurrentBalanceInfo.Scope || null);
        }
        else {
            return item.CurrentBalance;
        }
    }
});
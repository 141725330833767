angular.module('uti.skayo.onlinesale').controller('MenuController', function (LoginService, $rootScope, $scope, $http, $location, gettextCatalog, $uibModal, $window) {
    var loginService = LoginService;

    $scope.navCollapsed = true;

    $scope.isLoggedIn = function () {
        return loginService.isLoggedIn();
    };

    $scope.isSuperAdmin = function () {
        return loginService.isSuperAdmin();
    };

    $scope.isAdmin = function () {
        return loginService.isAdmin();
    };

    $scope.isImpersonating = function () {
        return loginService.isImpersonating();
    };

    $scope.currentUser = function () {
        return loginService.getCurrentUser();
    };

    $scope.impersonatingUser = function () {
        return loginService.getImpersonatingUser();
    };

    var languages = {
        en_US: 'English',
        ro: 'Română'
    };

    $scope.logout = function(){
        var modalInstance = $uibModal.open({
            //animation: $scope.animationsEnabled,
            templateUrl: '/app/views/confirm.html',
            controller: 'ConfirmController',
            windowClass: 'center-modal',
            //size: 'lg',
            resolve: {
                message: function () {
                    return gettextCatalog.getString("Are you sure you want to quit?");
                }
            }
        });

        modalInstance.result.then(function(res) {
            if (res === true) {
                loginService.logout();
            }
        });

    };

    $scope.isActive = function (viewLocation) {
        return viewLocation === $location.path();
    };

    $scope.getLanguage = function () {
        var languageCode = gettextCatalog.getCurrentLanguage();
        return languages[languageCode];
    };

    $scope.setLanguage = function (language) {
        localStorage.setItem("skayo-client-language", language);

        if (loginService.isLoggedIn()) {
            $http.post('api/myAccount/setlanguage', { Language: language }).then(function () {
                $window.location.reload();
            });
        } else {
            $window.location.reload();
        }
    };
});


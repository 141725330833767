angular.module('uti.skayo.onlinesale').controller('AdminUserAuditController', function ($scope, $http) {
    $scope.UserEmail = '';
    $scope.UserFirstName = '';
    $scope.UserLastName = '';
    $scope.AuditList = null;
    $scope.Action = null;
    $scope.actionList = [];

    //
    // Date Range Functionality
    $scope.setInitialDateRange = function () {
        var fromDate = new Date();
        var toDate = new Date();

        fromDate.setDate(fromDate.getDate() - 7);
        fromDate.setHours(0, 0, 0, 0);
        $scope.StartDate = fromDate;
        toDate.setHours(23, 59, 59, 999);
        $scope.EndDate = toDate;
    }
    $scope.setInitialDateRange();

    //
    // Action type filter
    $scope.fillActionList = function () {
        $http
            .post('api/adminUserAudit/ActionList', {})
            .then(function (result) { $scope.actionList = result.data; })
            .catch(function (error) { $scope.actionList = []; });
    };
    $scope.fillActionList();

    $scope.dateOptions = {
        maxDate: new Date(),
        showWeeks: false,
    };

    $scope.endDatePopupOpen = function () {
        $scope.endDatePopup.opened = true;
    };

    $scope.startDatePopupOpen = function () {
        $scope.startDatePopup.opened = true;
    };

    $scope.startDatePopup = {
        opened: false
    };

    $scope.endDatePopup = {
        opened: false
    };

    // 
    // -- Paging Functionality --
    $scope.paging = {};
    $scope.paging.pageSizeList = [5, 10, 15, 20, 50];
    $scope.paging.itemsPerPage = 15;
    $scope.paging.maxSize = 10;

    $scope.paging.clearPaging = function () {
        $scope.paging.currentPage = 0;
        $scope.paging.totalItems = 0;
    }
    $scope.paging.clearPaging();

    $scope.paging.pageRange = function () {
        var rangeSize = $scope.paging.maxSize;
        var start = $scope.paging.currentPage;
        var pages = $scope.paging.totalPages();
        var range = [];

        rangeSize = (pages < rangeSize) ? pages : rangeSize;
        start = (start > pages - rangeSize) ? pages - rangeSize :
                    (start < rangeSize) ? 0 : start;

        for (var i = start; i < start + rangeSize; i++) {
            range.push(i);
        }
        return range;
    };

    $scope.paging.totalPages = function () {
        return Math.ceil($scope.paging.totalItems / $scope.paging.itemsPerPage);
    };

    $scope.paging.setPage = function (n) {
        if (n >= 0 && n < $scope.paging.totalPages()) {
            $scope.paging.currentPage = n;
        }
    };

    $scope.$watch("paging.currentPage", function (newValue, oldValue) {
        if ($scope.paging.totalItems !== 0) {
            $scope.search(newValue);
        }
    });

    $scope.$watch("paging.itemsPerPage", function (newValue, oldValue) {
        if ($scope.paging.totalItems !== 0) {
            $scope.search(-1);
        }
    });

    $scope.getDateString = function (dateObject, beginning) {
        var day = dateObject.getDate();
        var month = dateObject.getMonth() + 1;
        var year = dateObject.getFullYear();

        return (day < 10 ? "0" + day : day) + "/"
            + (month < 10 ? "0" + month : month) + "/"
            + year
            + (beginning === true ? " 00:00:00" : " 23:59:59");
    }

    $scope.search = function (pageNumber) {
        if (pageNumber < 0) {
            $scope.paging.clearPaging();
            pageNumber = 0;
        }

        var startDateString = $scope.getDateString($scope.StartDate, true);
        var endDateString = $scope.getDateString($scope.EndDate, false);

        $http
            .post('api/adminUserAudit/AuditList', {
                UserEmail: $scope.UserEmail,
                UserFirstName: $scope.UserFirstName,
                UserLastName: $scope.UserLastName,
                StartDate: startDateString,
                EndDate: endDateString,
                Action: $scope.Action != null ? $scope.Action.Id : null,
                PageNumber: pageNumber,
                ItemsPerPage: $scope.paging.itemsPerPage
            })
            .then(function (result) { $scope.AuditList = result.data.Audits; $scope.paging.totalItems = result.data.Total; })
            .catch(function (error) { $scope.AuditList = null; $scope.paging.totalItems = 0; });
    };

    $scope.clear = function () {
        $scope.UserEmail = '';
        $scope.UserFirstName = '';
        $scope.UserLastName = '';
        $scope.AuditList = null;
        $scope.Action = null;
        $scope.setInitialDateRange();
        $scope.paging.clearPaging();
    };
})
angular.module('uti.skayo.onlinesale').controller('AdminSettingCreateController', function ($scope, $http, $location, gettextCatalog) {
    $scope.errors = null;
    $scope.types = ['BOOL', 'DATE', 'DECIMAL', 'INT', 'RADIO', 'STRING'];
    $scope.param = {
        Id: null,
        Type: null,
        Key: null,
        ValueBOOL: null,
        ValueINT: null,
        ValueDECIMAL: null,
        ValueSTRING: null,
        ValueDATE: null,
        ValueRADIO: null
    };

    $scope.save = function () {
        $http.post('api/adminSettingCreate', $scope.param)
             .then(function (data) {
                 debugger;
                 if (data.data.Errors.length === 0)
                     $location.path('/adminSettings');
                 else
                     $scope.errors = data.data.Errors;
             })
             .catch(function (error) {
                 $scope.errors = [error];
             });
    }

    $scope.updateType = function () {
        $scope.param.ValueBOOL = null;
        $scope.param.ValueINT = null;
        $scope.param.ValueDECIMAL = null;
        $scope.param.ValueSTRING = null;
        $scope.param.ValueDATE = null;
        $scope.param.ValueRADIO = null;

        switch ($scope.param.Type) {
            case 'BOOL': $scope.param.ValueBOOL = false; break;
            case 'INT': $scope.param.ValueINT = 0; break;
            case 'DECIMAL': $scope.param.ValueDECIMAL = 0; break;
            case 'STRING': $scope.param.ValueSTRING = ''; break;
            case 'DATE': {
                var now = new Date();
                $scope.param.ValueDATE = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
                break;
            }
            case 'RADIO': $scope.param.ValueRADIO = { Options: [] }; break;
        }
    }

    $scope.newOption = { Value: '', Text: '' };

    $scope.addOption = function () {
        $scope.param.ValueRADIO.Options.push({ Value: $scope.newOption.Value, Text: $scope.newOption.Text });
        $scope.newOption.Value = '';
        $scope.newOption.Text = '';
    }

    $scope.removeOption = function (option) {
        var index = $scope.param.ValueRADIO.Options.indexOf(option);
        $scope.param.ValueRADIO.Options.splice(index, 1);
    }

    $scope.getTranslation = function (text) {
        return gettextCatalog.getString(text);
    }
})
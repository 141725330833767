angular.module('uti.skayo.onlinesale').service('SkayoLocaleService', function ($locale) {
    var PLURAL_CATEGORY = { ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other" };

    var getEnUs = function () {
        var PLURAL_CATEGORY = { ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other" };
        function getDecimals(n) {
            n = n + '';
            var i = n.indexOf('.');
            return (i == -1) ? 0 : n.length - i - 1;
        }

        function getVF(n, opt_precision) {
            debugger;
            var v = opt_precision;

            if (undefined === v) {
                v = Math.min(getDecimals(n), 3);
            }

            var base = Math.pow(10, v);
            var f = ((n * base) | 0) % base;
            return { v: v, f: f };
        }

        return {
            "DATETIME_FORMATS": {
                "AMPMS": [
                  "AM",
                  "PM"
                ],
                "DAY": [
                  "Sunday",
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday"
                ],
                "ERANAMES": [
                  "Before Christ",
                  "Anno Domini"
                ],
                "ERAS": [
                  "BC",
                  "AD"
                ],
                "FIRSTDAYOFWEEK": 6,
                "MONTH": [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December"
                ],
                "SHORTDAY": [
                  "Sun",
                  "Mon",
                  "Tue",
                  "Wed",
                  "Thu",
                  "Fri",
                  "Sat"
                ],
                "SHORTMONTH": [
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec"
                ],
                "STANDALONEMONTH": [
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December"
                ],
                "WEEKENDRANGE": [
                  5,
                  6
                ],
                "fullDate": "EEEE, MMMM d, y",
                "longDate": "MMMM d, y",
                "medium": "MMM d, y h:mm:ss a",
                "mediumDate": "MMM d, y",
                "mediumTime": "h:mm:ss a",
                "short": "M/d/yy h:mm a",
                "shortDate": "M/d/yy",
                "shortTime": "h:mm a"
            },
            "NUMBER_FORMATS": {
                "CURRENCY_SYM": "$",
                "DECIMAL_SEP": ".",
                "GROUP_SEP": ",",
                "PATTERNS": [
                  {
                      "gSize": 3,
                      "lgSize": 3,
                      "maxFrac": 3,
                      "minFrac": 0,
                      "minInt": 1,
                      "negPre": "-",
                      "negSuf": "",
                      "posPre": "",
                      "posSuf": ""
                  },
                  {
                      "gSize": 3,
                      "lgSize": 3,
                      "maxFrac": 2,
                      "minFrac": 2,
                      "minInt": 1,
                      "negPre": "-\u00a4",
                      "negSuf": "",
                      "posPre": "\u00a4",
                      "posSuf": ""
                  }
                ]
            },
            "id": "en-us",
            "localeID": "en_US",
            "pluralCat": function (n, opt_precision) { var i = n | 0; var vf = getVF(n, opt_precision); if (i == 1 && vf.v == 0) { return PLURAL_CATEGORY.ONE; } return PLURAL_CATEGORY.OTHER; }
        };
    };

    var getRu = function() {
        var PLURAL_CATEGORY = {ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other"};
        function getDecimals(n) {
            n = n + '';
            var i = n.indexOf('.');
            return (i == -1) ? 0 : n.length - i - 1;
        }

        function getVF(n, opt_precision) {
            var v = opt_precision;

            if (undefined === v) {
                v = Math.min(getDecimals(n), 3);
            }

            var base = Math.pow(10, v);
            var f = ((n * base) | 0) % base;
            return {v: v, f: f};
        }

        return {
            "DATETIME_FORMATS": {
                "AMPMS": [
                  "\u0414\u041f",
                  "\u041f\u041f"
                ],
                "DAY": [
                  "\u0432\u043e\u0441\u043a\u0440\u0435\u0441\u0435\u043d\u044c\u0435",
                  "\u043f\u043e\u043d\u0435\u0434\u0435\u043b\u044c\u043d\u0438\u043a",
                  "\u0432\u0442\u043e\u0440\u043d\u0438\u043a",
                  "\u0441\u0440\u0435\u0434\u0430",
                  "\u0447\u0435\u0442\u0432\u0435\u0440\u0433",
                  "\u043f\u044f\u0442\u043d\u0438\u0446\u0430",
                  "\u0441\u0443\u0431\u0431\u043e\u0442\u0430"
                ],
                "ERANAMES": [
                  "\u0434\u043e \u0420\u043e\u0436\u0434\u0435\u0441\u0442\u0432\u0430 \u0425\u0440\u0438\u0441\u0442\u043e\u0432\u0430",
                  "\u043e\u0442 \u0420\u043e\u0436\u0434\u0435\u0441\u0442\u0432\u0430 \u0425\u0440\u0438\u0441\u0442\u043e\u0432\u0430"
                ],
                "ERAS": [
                  "\u0434\u043e \u043d. \u044d.",
                  "\u043d. \u044d."
                ],
                "FIRSTDAYOFWEEK": 0,
                "MONTH": [
                  "\u044f\u043d\u0432\u0430\u0440\u044f",
                  "\u0444\u0435\u0432\u0440\u0430\u043b\u044f",
                  "\u043c\u0430\u0440\u0442\u0430",
                  "\u0430\u043f\u0440\u0435\u043b\u044f",
                  "\u043c\u0430\u044f",
                  "\u0438\u044e\u043d\u044f",
                  "\u0438\u044e\u043b\u044f",
                  "\u0430\u0432\u0433\u0443\u0441\u0442\u0430",
                  "\u0441\u0435\u043d\u0442\u044f\u0431\u0440\u044f",
                  "\u043e\u043a\u0442\u044f\u0431\u0440\u044f",
                  "\u043d\u043e\u044f\u0431\u0440\u044f",
                  "\u0434\u0435\u043a\u0430\u0431\u0440\u044f"
                ],
                "SHORTDAY": [
                  "\u0432\u0441",
                  "\u043f\u043d",
                  "\u0432\u0442",
                  "\u0441\u0440",
                  "\u0447\u0442",
                  "\u043f\u0442",
                  "\u0441\u0431"
                ],
                "SHORTMONTH": [
                  "\u044f\u043d\u0432.",
                  "\u0444\u0435\u0432\u0440.",
                  "\u043c\u0430\u0440.",
                  "\u0430\u043f\u0440.",
                  "\u043c\u0430\u044f",
                  "\u0438\u044e\u043d.",
                  "\u0438\u044e\u043b.",
                  "\u0430\u0432\u0433.",
                  "\u0441\u0435\u043d\u0442.",
                  "\u043e\u043a\u0442.",
                  "\u043d\u043e\u044f\u0431.",
                  "\u0434\u0435\u043a."
                ],
                "STANDALONEMONTH": [
                  "\u044f\u043d\u0432\u0430\u0440\u044c",
                  "\u0444\u0435\u0432\u0440\u0430\u043b\u044c",
                  "\u043c\u0430\u0440\u0442",
                  "\u0430\u043f\u0440\u0435\u043b\u044c",
                  "\u043c\u0430\u0439",
                  "\u0438\u044e\u043d\u044c",
                  "\u0438\u044e\u043b\u044c",
                  "\u0430\u0432\u0433\u0443\u0441\u0442",
                  "\u0441\u0435\u043d\u0442\u044f\u0431\u0440\u044c",
                  "\u043e\u043a\u0442\u044f\u0431\u0440\u044c",
                  "\u043d\u043e\u044f\u0431\u0440\u044c",
                  "\u0434\u0435\u043a\u0430\u0431\u0440\u044c"
                ],
                "WEEKENDRANGE": [
                  5,
                  6
                ],
                "fullDate": "EEEE, d MMMM y '\u0433'.",
                "longDate": "d MMMM y '\u0433'.",
                "medium": "d MMM y '\u0433'. H:mm:ss",
                "mediumDate": "d MMM y '\u0433'.",
                "mediumTime": "H:mm:ss",
                "short": "dd.MM.y H:mm",
                "shortDate": "dd.MM.y",
                "shortTime": "H:mm"
            },
            "NUMBER_FORMATS": {
                "CURRENCY_SYM": "\u20bd",
                "DECIMAL_SEP": ",",
                "GROUP_SEP": "\u00a0",
                "PATTERNS": [
                  {
                      "gSize": 3,
                      "lgSize": 3,
                      "maxFrac": 3,
                      "minFrac": 0,
                      "minInt": 1,
                      "negPre": "-",
                      "negSuf": "",
                      "posPre": "",
                      "posSuf": ""
                  },
                  {
                      "gSize": 3,
                      "lgSize": 3,
                      "maxFrac": 2,
                      "minFrac": 2,
                      "minInt": 1,
                      "negPre": "-",
                      "negSuf": "\u00a0\u00a4",
                      "posPre": "",
                      "posSuf": "\u00a0\u00a4"
                  }
                ]
            },
            "id": "ru",
            "localeID": "ru",
            "pluralCat": function (n, opt_precision) { var i = n | 0; var vf = getVF(n, opt_precision); if (vf.v == 0 && i % 10 == 1 && i % 100 != 11) { return PLURAL_CATEGORY.ONE; } if (vf.v == 0 && i % 10 >= 2 && i % 10 <= 4 && (i % 100 < 12 || i % 100 > 14)) { return PLURAL_CATEGORY.FEW; } if (vf.v == 0 && i % 10 == 0 || vf.v == 0 && i % 10 >= 5 && i % 10 <= 9 || vf.v == 0 && i % 100 >= 11 && i % 100 <= 14) { return PLURAL_CATEGORY.MANY; } return PLURAL_CATEGORY.OTHER; }
        };
    };

    var getRo = function () {
        var PLURAL_CATEGORY = { ZERO: "zero", ONE: "one", TWO: "two", FEW: "few", MANY: "many", OTHER: "other" };
        function getDecimals(n) {
            n = n + '';
            var i = n.indexOf('.');
            return (i == -1) ? 0 : n.length - i - 1;
        }

        function getVF(n, opt_precision) {
            var v = opt_precision;

            if (undefined === v) {
                v = Math.min(getDecimals(n), 3);
            }

            var base = Math.pow(10, v);
            var f = ((n * base) | 0) % base;
            return { v: v, f: f };
        }
        return {
            "DATETIME_FORMATS": {
                "AMPMS": [
                    "a.m.",
                    "p.m."
                ],
                "DAY": [
                    "duminic\u0103",
                    "luni",
                    "mar\u021bi",
                    "miercuri",
                    "joi",
                    "vineri",
                    "s\u00e2mb\u0103t\u0103"
                ],
                "ERANAMES": [
                    "\u00eenainte de Hristos",
                    "dup\u0103 Hristos"
                ],
                "ERAS": [
                    "\u00ee.Hr.",
                    "d.Hr."
                ],
                "FIRSTDAYOFWEEK": 0,
                "MONTH": [
                    "ianuarie",
                    "februarie",
                    "martie",
                    "aprilie",
                    "mai",
                    "iunie",
                    "iulie",
                    "august",
                    "septembrie",
                    "octombrie",
                    "noiembrie",
                    "decembrie"
                ],
                "SHORTDAY": [
                    "Dum",
                    "Lun",
                    "Mar",
                    "Mie",
                    "Joi",
                    "Vin",
                    "S\u00e2m"
                ],
                "SHORTMONTH": [
                    "ian.",
                    "feb.",
                    "mar.",
                    "apr.",
                    "mai",
                    "iun.",
                    "iul.",
                    "aug.",
                    "sept.",
                    "oct.",
                    "nov.",
                    "dec."
                ],
                "WEEKENDRANGE": [
                    5,
                    6
                ],
                "fullDate": "EEEE, d MMMM y",
                "longDate": "d MMMM y",
                "medium": "d MMM y HH:mm:ss",
                "mediumDate": "d MMM y",
                "mediumTime": "HH:mm:ss",
                "short": "dd.MM.y HH:mm",
                "shortDate": "dd.MM.y",
                "shortTime": "HH:mm"
            },
            "NUMBER_FORMATS": {
                "CURRENCY_SYM": "RON",
                "DECIMAL_SEP": ",",
                "GROUP_SEP": ".",
                "PATTERNS": [
                    {
                        "gSize": 3,
                        "lgSize": 3,
                        "maxFrac": 3,
                        "minFrac": 0,
                        "minInt": 1,
                        "negPre": "-",
                        "negSuf": "",
                        "posPre": "",
                        "posSuf": ""
                    },
                    {
                        "gSize": 3,
                        "lgSize": 3,
                        "maxFrac": 2,
                        "minFrac": 2,
                        "minInt": 1,
                        "negPre": "-",
                        "negSuf": "\u00a0\u00a4",
                        "posPre": "",
                        "posSuf": "\u00a0\u00a4"
                    }
                ]
            },
            "id": "ro",
            "localeID": "ro",
            "pluralCat": function (n, opt_precision) { var i = n | 0; var vf = getVF(n, opt_precision); if (i == 1 && vf.v == 0) { return PLURAL_CATEGORY.ONE; } if (vf.v != 0 || n == 0 || n != 1 && n % 100 >= 1 && n % 100 <= 19) { return PLURAL_CATEGORY.FEW; } return PLURAL_CATEGORY.OTHER; }
        };
     
    };

    var locales = {
        bg: {
            "DATETIME_FORMATS": {
                "AMPMS": [
                  "\u043f\u0440.\u043e\u0431.",
                  "\u0441\u043b.\u043e\u0431."
                ],
                "DAY": [
                  "\u043d\u0435\u0434\u0435\u043b\u044f",
                  "\u043f\u043e\u043d\u0435\u0434\u0435\u043b\u043d\u0438\u043a",
                  "\u0432\u0442\u043e\u0440\u043d\u0438\u043a",
                  "\u0441\u0440\u044f\u0434\u0430",
                  "\u0447\u0435\u0442\u0432\u044a\u0440\u0442\u044a\u043a",
                  "\u043f\u0435\u0442\u044a\u043a",
                  "\u0441\u044a\u0431\u043e\u0442\u0430"
                ],
                "ERANAMES": [
                  "\u043f\u0440\u0435\u0434\u0438 \u0425\u0440\u0438\u0441\u0442\u0430",
                  "\u0441\u043b\u0435\u0434 \u0425\u0440\u0438\u0441\u0442\u0430"
                ],
                "ERAS": [
                  "\u043f\u0440.\u0425\u0440.",
                  "\u0441\u043b.\u0425\u0440."
                ],
                "FIRSTDAYOFWEEK": 0,
                "MONTH": [
                  "\u044f\u043d\u0443\u0430\u0440\u0438",
                  "\u0444\u0435\u0432\u0440\u0443\u0430\u0440\u0438",
                  "\u043c\u0430\u0440\u0442",
                  "\u0430\u043f\u0440\u0438\u043b",
                  "\u043c\u0430\u0439",
                  "\u044e\u043d\u0438",
                  "\u044e\u043b\u0438",
                  "\u0430\u0432\u0433\u0443\u0441\u0442",
                  "\u0441\u0435\u043f\u0442\u0435\u043c\u0432\u0440\u0438",
                  "\u043e\u043a\u0442\u043e\u043c\u0432\u0440\u0438",
                  "\u043d\u043e\u0435\u043c\u0432\u0440\u0438",
                  "\u0434\u0435\u043a\u0435\u043c\u0432\u0440\u0438"
                ],
                "SHORTDAY": [
                  "\u043d\u0434",
                  "\u043f\u043d",
                  "\u0432\u0442",
                  "\u0441\u0440",
                  "\u0447\u0442",
                  "\u043f\u0442",
                  "\u0441\u0431"
                ],
                "SHORTMONTH": [
                  "\u044f\u043d\u0443",
                  "\u0444\u0435\u0432",
                  "\u043c\u0430\u0440\u0442",
                  "\u0430\u043f\u0440",
                  "\u043c\u0430\u0439",
                  "\u044e\u043d\u0438",
                  "\u044e\u043b\u0438",
                  "\u0430\u0432\u0433",
                  "\u0441\u0435\u043f",
                  "\u043e\u043a\u0442",
                  "\u043d\u043e\u0435",
                  "\u0434\u0435\u043a"
                ],
                "STANDALONEMONTH": [
                  "\u044f\u043d\u0443\u0430\u0440\u0438",
                  "\u0444\u0435\u0432\u0440\u0443\u0430\u0440\u0438",
                  "\u043c\u0430\u0440\u0442",
                  "\u0430\u043f\u0440\u0438\u043b",
                  "\u043c\u0430\u0439",
                  "\u044e\u043d\u0438",
                  "\u044e\u043b\u0438",
                  "\u0430\u0432\u0433\u0443\u0441\u0442",
                  "\u0441\u0435\u043f\u0442\u0435\u043c\u0432\u0440\u0438",
                  "\u043e\u043a\u0442\u043e\u043c\u0432\u0440\u0438",
                  "\u043d\u043e\u0435\u043c\u0432\u0440\u0438",
                  "\u0434\u0435\u043a\u0435\u043c\u0432\u0440\u0438"
                ],
                "WEEKENDRANGE": [
                  5,
                  6
                ],
                "fullDate": "EEEE, d MMMM y '\u0433'.",
                "longDate": "d MMMM y '\u0433'.",
                "medium": "d.MM.y '\u0433'. H:mm:ss",
                "mediumDate": "d.MM.y '\u0433'.",
                "mediumTime": "H:mm:ss",
                "short": "d.MM.yy '\u0433'. H:mm",
                "shortDate": "d.MM.yy '\u0433'.",
                "shortTime": "H:mm"
            },
            "NUMBER_FORMATS": {
                "CURRENCY_SYM": "lev",
                "DECIMAL_SEP": ",",
                "GROUP_SEP": "\u00a0",
                "PATTERNS": [
                  {
                      "gSize": 3,
                      "lgSize": 3,
                      "maxFrac": 3,
                      "minFrac": 0,
                      "minInt": 1,
                      "negPre": "-",
                      "negSuf": "",
                      "posPre": "",
                      "posSuf": ""
                  },
                  {
                      "gSize": 0,
                      "lgSize": 0,
                      "maxFrac": 2,
                      "minFrac": 2,
                      "minInt": 1,
                      "negPre": "-",
                      "negSuf": "\u00a0\u00a4",
                      "posPre": "",
                      "posSuf": "\u00a0\u00a4"
                  }
                ]
            },
            "id": "bg-bg",
            "localeID": "bg_BG",
            "pluralCat": function (n, opt_precision) { if (n == 1) { return PLURAL_CATEGORY.ONE; } return PLURAL_CATEGORY.OTHER; }
        },
        en_US: getEnUs(),
        ru: getRu(),
        ro: getRo()
    };

    this.setLocale = function (key) {
        if(locales[key])
            angular.copy(locales[key], $locale);
        else
            angular.copy(locales["en_US"], $locale);
    }
});

